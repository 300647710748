.cardstype-container {
  padding: 0 12rem;
}

/* media query starts */
@media (max-width: 1400px) {
  .cardstype-container {
    padding: 0 5rem;
  }
}
@media (max-width: 1025px) {
  .cardstype-container {
    padding: 0 3rem;
  }
}
@media (max-width: 840px) {
  .cardstype-container .m-8a5d1357 {
    font-size: 18px;
  }
}
@media (max-width: 700px) {
  .cardstype-container {
    padding: 0 2rem;
  }
}
@media (max-width: 500px) {
  .cardstype-container {
    padding: 0 1rem;
  }
}
/* media query ends */