.permission{
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.go_backbutton{
    width: 150px;
    height: 40px;
    background: linear-gradient(45deg, #142068,#1089ad) !important;
    color: white;
    font-weight: bold;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    text-decoration: none !important;
}