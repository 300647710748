.welcome-edit-qr-image {
  width: 100%;
  height: 55vh;
  padding: 0 7rem;
  margin: 5rem 0 5rem 0;
}

.welcome-edit-qr-image img {
  width: 100%;
  height: 100%;
  object-fit: fill;
}

/* media query starts */
@media (max-width: 1025px) {
  .welcome-edit-qr-image {
    padding: 0 4rem;
    height: 100%;
  }
}
@media (max-width: 870px) {
  .welcome-edit-qr-image {
    padding: 0 2rem;
    margin: 2rem 0 2rem 0;
  }
}
/* media query ends */