.state-dynamic-container {
  width: 100%;
  height: 100%;
  padding: 0 12rem;
}

.state-dynamic-image-container {
  width: 100%;
  height: 100%;
  background: transparent;
}

.state-dynamic-image-container img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  background: transparent;
}

.state-dynamic-content {
  display: flex;
  flex-direction: column;
  /* width: 500px; */
}

.state-dynamic-content-icon {
  padding: 1.3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  width: fit-content;
  background-color: var(--Logo-Color3);
  color: white;
}

.state-dynamic-content > div {
  margin: 1rem 0;
}

.state-dynamic-content div:nth-child(2) {
  margin: 0;
}

/* media query starts */
@media (max-width: 1400px) {
  .state-dynamic-container {
    padding: 0 5rem;
  }
}
@media (max-width: 1025px) {
  .state-dynamic-container {
    padding: 0 4rem;
  }
}
@media (max-width: 700px) {
  .state-dynamic-container {
    padding: 0 2rem;
  }
}
@media (max-width: 500px) {
  .state-dynamic-container {
    padding: 0 1rem;
  }
}
/* media query ends */
