.react-date-picker-vcard {
  height: 4vh;
  border-radius: 8px;
  width: 130%;
  border: 1px solid #ced4da;
  padding-left: 0.5rem;
}

.social-links-div {
  display: flex;
  gap: 0.2rem;
  justify-content: space-between;
}

.social-link-btn {
  font-size: 10px;
  padding: 10px;
  color: black !important;
}

.vcard-facebook-content,
.vcard-instagram-content,
.vcard-twitter-content,
.vcard-youtube-content,
.vcard-linkedin-content {
  padding-top: 0.5rem;
}

.social-link-active-btn {
  background-color: var(--Logo-Color5);
  color: white !important;
}

.react-date-picker-vcard-landingPage {
  height: 4.5vh;
  border-radius: 8px;
  width: 100%;
  border: 1px solid #ced4da;
  padding-left: 0.5rem;
}

.crop-modal-content {
  width: 100%;
  height: 75vh;
}

.model-btn,
.model-btn-services {
  background-color: white !important;
  height: 100px !important;
  width: 100% !important;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed lightgrey;
  color: black !important;
  /* background-image: linear-gradient(to right, ); */
}

.model-btn-image {
  height: 150px !important;
  width: 100% !important;
}

.model-btn-services {
  height: 50px !important;
  border: 1px solid lightgray !important;
}

.modal_edit_icons {
  position: absolute;
  right: 10;
}

:where(.css-dev-only-do-not-override-1uweeqc).ant-btn-primary,:where(.css-dev-only-do-not-override-1uweeqc).ant-switch.ant-switch-small .ant-switch-inner{
  background-color: #1357be !important;
}