/* Google Fonts */
@import url("https://fonts.googleapis.com/css2?family=Righteous&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:wght@600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:wght@600&family=Teko&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
  scrollbar-width: none !important;
}

:root {
  --Logo-Color1: #ffe252;
  --Logo-Color1-Light: #fce664;
  --Logo-Color2: #292f42;
  --Logo-Color2-Light: #444c65;
  --Logo-Color3: #1357be;
  --Logo-Color3-Light: #1357be;
  --Logo-Color4: #e56100;
  --Logo-Color5: #2b3659;
  --Border-Color: rgba(216, 216, 216, 0.471);
  --Footer-Font-Color: rgba(255, 255, 255, 0.907);
}

.ant-table-content::-webkit-scrollbar {
  display: none !important;
  height: 0px !important;
}

::-webkit-scrollbar-thumb {
  background-color: #888 !important;
  border-radius: 2px !important;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

::selection {
  background-color: black;
  columns: var(--Logo-Color3);
}

.ant-input,
.ant-select {
  height: 40px !important;
  font-size: 16px !important;
}

.ant-input-outlined,
.ant-select-selector {
  border: 1px groove gray !important;
}

:where(.css-dev-only-do-not-override-1uweeqc).ant-table-wrapper
  .ant-table-thead
  > tr
  > th,
:where(.css-dev-only-do-not-override-1uweeqc).ant-table-wrapper
  .ant-table-thead
  > tr
  > td {
  background: #fff;
  /* color: white !important; */
}
:where(.css-dev-only-do-not-override-1uweeqc).ant-form input[type="file"] {
  border: transparent !important;
}
:where(.css-dev-only-do-not-override-1uweeqc).ant-card .ant-card-body,
.ant-card-body,
:where(.css-dev-only-do-not-override-1uweeqc).ant-card-bordered
  .ant-card-cover {
  border-radius: 10px !important;
}
:where(.css-dev-only-do-not-override-1uweeqc).ant-table-wrapper
  .ant-table-tbody
  > tr
  > th,
:where(.css-dev-only-do-not-override-1uweeqc).ant-table-wrapper
  .ant-table-tbody
  > tr
  > td {
  color: black !important;
  /* font-weight: bold !important; */
}

:where(.css-dev-only-do-not-override-1uweeqc).ant-pagination
  .ant-pagination-item-active {
  border-radius: 10px !important;
  /* background: var(--Logo-Color3-Light) !important; */
  border: transparent !important;
  color: #e56100 !important;
}

:where(.css-dev-only-do-not-override-1uweeqc).ant-pagination
  .ant-pagination-item-active
  a {
  /* color: white !important; */
}

.scoll-block::-webkit-scrollbar {
  scrollbar-width: none !important;
  display: none !important;
}

.scroll-display::-webkit-scrollbar {
  display: none !important;
  scrollbar-width: none !important;
}

.swiper-pagination-bullet-active {
  /* background: linear-gradient(to right, #1357be, #1357be) !important; */
}

:where(
    .css-dev-only-do-not-override-1uweeqc
  ).ant-descriptions.ant-descriptions-bordered
  > .ant-descriptions-view
  .ant-descriptions-row
  > .ant-descriptions-item-label {
  display: none !important;
}

/* antd overide card body */
:where(.css-dev-only-do-not-override-1uweeqc).ant-card .ant-card-body,
.ant-card-body,
:where(.css-dev-only-do-not-override-1uweeqc).ant-card-bordered
  .ant-card-cover {
  background: white !important;
}
:where(.css-dev-only-do-not-override-1uweeqc).ant-picker .ant-picker-suffix {
  color: white !important;
}

:where(.css-dev-only-do-not-override-1uweeqc).ant-input:placeholder-shown {
  border-color: lightgray !important;
}

.vcard3 :where(.css-dev-only-do-not-override-1uweeqc).ant-picker-outlined,
.vcard3 .ant-input-outlined,
.ant-select-selector {
  border-color: transparent !important;
}
.appoinment
  :where(.css-dev-only-do-not-override-1uweeqc).ant-table-wrapper
  .ant-table-thead
  > tr
  > th,
:where(.css-dev-only-do-not-override-1uweeqc).ant-table-wrapper
  .ant-table-thead
  > tr
  > td {
  background-color: lightgray !important;
}
.text-area .ant-input-outlined {
  height: 200px !important;
}
.text-area2 .ant-input-outlined {
  height: 100px !important;
  width: 400px !important;
}

.text-area3 .ant-input-outlined {
  height: 100px !important;
}
.ant-collapse-header-text {
  font-family: Poppins !important;
}

.text-area20 .ant-input-outlined {
  height: 100px !important;
}
.text-area-service .ant-input-outlined {
  height: 100px !important;
}

:where(.css-dev-only-do-not-override-1uweeqc).ant-btn-primary:disabled,
:where(.css-dev-only-do-not-override-1uweeqc).ant-btn-primary.ant-btn-disabled {
  background-color: #1357be !important;
}

:where(.css-dev-only-do-not-override-1uweeqc).ant-upload-wrapper
  .ant-upload-list
  .ant-upload-list-item-error {
  width: 100px !important;
  color: green !important;
}
:where(.css-dev-only-do-not-override-1uweeqc).ant-upload-wrapper
  .ant-upload-list
  .ant-upload-list-item-error
  .ant-upload-list-item-name,
:where(.css-dev-only-do-not-override-1uweeqc).ant-upload-wrapper
  .ant-upload-list
  .ant-upload-list-item-error
  .ant-upload-icon
  .anticon {
  color: green !important;
}

.border-less .ant-input-outlined {
  border: transparent !important;
  background: white !important;
  color: #1357be !important;
  font-family: "Poppins" !important;
}

.appoinment :where(.css-dev-only-do-not-override-1uweeqc).ant-btn-primary,
.appoinment
  :where(.css-dev-only-do-not-override-1uweeqc).ant-switch.ant-switch-small
  .ant-switch-inner,
.appoinment :where(.css-dev-only-do-not-override-1uweeqc).ant-btn-primary,
:where(.css-dev-only-do-not-override-1uweeqc).ant-btn {
  color: white !important;
}

:where(.css-dev-only-do-not-override-1uweeqc).ant-collapse
  .ant-collapse-item-disabled
  > .ant-collapse-header,
:where(.css-dev-only-do-not-override-1uweeqc).ant-collapse
  .ant-collapse-item-disabled
  > .ant-collapse-header
  > .arrow {
  color: #1357be !important;
}

:where(.css-1uweeqc).ant-btn > span {
  color: black !important;
}

:where(.css-dev-only-do-not-override-1uweeqc).ant-form-vertical
  .ant-form-item-label
  > label,
:where(.css-dev-only-do-not-override-1uweeqc).ant-col-24.ant-form-item-label
  > label,
:where(.css-dev-only-do-not-override-1uweeqc).ant-col-xl-24.ant-form-item-label
  > label {
  font-family: "Poppins" !important;
}

.havala canvas {
  width: 100% !important;
}

@layer components {
  .plan_type_button {
    @apply px-3 py-1 text-white  flex items-center justify-center;
  }
  .bg-color {
    @apply !bg-gradient-to-r from-[#1357be] to-[#1357be];
  }
  .center_div {
    @apply flex items-center justify-center;
  }
  .gradient {
    @apply bg-gradient-to-r from-darkpink to-lightpink;
  }
  .bg-text-color {
    @apply !bg-clip-text !text-transparent !bg-gradient-to-r from-[#1357be] to-[#1357be] font-bold;
  }
  .vcard1_title {
    @apply text-darkpink font-extrabold capitalize !font-Poppins !text-[16px];
  }
  .vcard1_header {
    @apply w-screen  flex flex-col items-center;
  }
  .antd_input {
    @apply !h-[40px]   w-[90%] mx-auto rounded-md indent-2;
  }
  .vcard2_heading {
    @apply text-vcard2PrimaryText font-Poppins font-bold self-start  px-4 flex items-center gap-x-1;
  }
  .vcard2_button {
    @apply !h-[50px] !bg-vcard2PrimaryText !text-black !font-Poppins !font-bold !border-none;
  }
  .vcard3_button {
    @apply !h-[50px]  !text-white !font-Poppins !font-bold !border-none;
  }
  .vcard3_heading {
    @apply text-vcard3PrimaryText font-Poppins font-bold  flex items-center gap-x-1;
  }
}
