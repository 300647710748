.footer-container {
  width: 100%;
  padding: 3rem 12rem 0 12rem;
  display: grid;
  justify-content: center;
  text-align: center;
  margin-top: 2rem;
  border-top: 0.3px solid #1357be79;
}

.footer-container-logo {
  width: 80px;
  height: 70px;
  margin: auto;
}

.footer-container-logo img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.footer-links ul {
  display: flex;
  margin: 1rem 0;
  gap: 2rem;
}

.footer-links ul li {
  list-style-type: none;
  cursor: pointer;
}

/* Media Query */

@media (max-width: 1400px) {
  .footer-container {
    padding: 3rem 5rem 0 5rem;
  }
}

@media (max-width: 824px) {
  .footer-container {
    padding: 3rem 2rem 0 2rem;
  }
}
@media (max-width: 500px) {
  .footer-links ul {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
}

/* Media Query End */
