.generate-qr-div-content {
  width: 100%;
  padding: 0.8rem 1.5rem;
}

.generate-qr-div-content-qr-type-btn1 {
  width: 100%;
  display: grid;
  grid-template-columns: auto auto auto auto auto auto auto;
  justify-content: center;
  gap: 1rem;
  margin: auto;
}

.generate-qr-div-content-qr-type-btn2 {
  width: 80%;
  display: grid;
  grid-template-columns: auto auto auto auto auto;
  justify-content: center;
  gap: 1rem;
  margin: auto;
  padding-top: 1rem;
}

.generate-qr-div-content-qr-type-btn1 button,
.generate-qr-div-content-qr-type-btn3 button,
.generate-qr-div-content-qr-type-btn2 button {
  width: 100%;
  /* border: 1px solid black; */
  border-radius: 20px;
  background-color: transparent;
  cursor: pointer;
  padding: 0.5rem 2rem;
}

.generate-qr-div-content-qr-type-btn1 .active,
.generate-qr-div-content-qr-type-btn3 .active,
.generate-qr-div-content-qr-type-btn2 .active {
  background-color: var(--Logo-Color3);
  border: 1px solid var(--Logo-Color3);
  color: white;
}

.generate-qr-div-content-qr-type-btn1 button:hover,
.generate-qr-div-content-qr-type-btn3 button:hover,
.generate-qr-div-content-qr-type-btn2 button:hover {
  border: 1px solid rgb(182, 182, 182);
  background-color: rgb(182, 182, 182);
  color: white;
}

.generate-qr-div-content-qr-type-btn-content {
  margin-top: 1rem;
  width: 100%;
  display: grid;
  grid-template-columns: 60% 35%;
  justify-content: space-between;
}

.generate-qr-div-content-qr-type-btn-content-qr-form {
  width: 100%;
}

.generate-qr-div-content-qr-type-btn-content-qr-code {
  width: 100%;
}

/* Media Query */

@media (max-width: 1100px) {
  .generate-qr-div-content-qr-type-btn1 {
    grid-template-columns: auto auto auto auto auto;
  }
}
@media (max-width: 786px) {
  .generate-qr-div-content-qr-type-btn1 {
    grid-template-columns: auto auto auto;
  }
  .generate-qr-div-content-qr-type-btn2 {
    grid-template-columns: auto auto auto;
  }
  .generate-qr-div-content-qr-type-btn3 {
    width: 100%;
    display: grid;
    grid-template-columns: auto auto auto auto;
    justify-content: center;
    gap: 1rem;
    margin: auto;
  }
}
@media (max-width: 680px) {
  .generate-qr-div-content-qr-type-btn3 {
    grid-template-columns: auto auto auto;
  }
}
@media (max-width: 550px) {
  .generate-qr-div-content-qr-type-btn3 {
    grid-template-columns: auto auto;
  }
}

/* Media Query End */
