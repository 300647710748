.welcome-banner-container-title {
  width: 100%;
  padding: 2rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.welcome-banner-container-title h1 {
  font-size: 4rem;
  color: black;
  font-family: "Righteous";
  font-weight: 200;
}

.welcome-banner-container-title p {
  font-size: 1.5rem;
}

.welcome-banner-container-title-qr {
  color: var(--Logo-Color3);
}

.welcome-banner-container-btns div button:nth-child(1) {
  background-color: var(--Logo-Color3);
}

.welcome-banner-container-btns div button:nth-child(2) {
  background-color: var(--Logo-Color5);
}

.bg_transparent_color {
  background: rgba(171, 187, 243, 0.13);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
}

/* media query starts */
@media (max-width: 815px) {
  .welcome-banner-container-title h1 {
    font-size: 2.6rem;
    padding: 0rem;
  }

  .welcome-banner-container-title p {
    font-size: 1.2rem;
  }

  .welcome-banner-container-btns .welcome-btn-style {
    font-size: 16px;
    height: 38px;
    padding: 0 18px;
  }
}

@media (max-width: 769px) {
  .welcome-banner-container-title h1 {
    font-size: 2.6rem;
  }

  .welcome-banner-container-title p {
    font-size: 1.3rem;
  }
}

@media (max-width: 550px) {
  .welcome-banner-container-title {
    padding: 1rem;
  }

  .welcome-banner-container-title h1 {
    font-size: 1.6rem;
    padding: 0rem;
  }

  .welcome-banner-container-title p {
    font-size: 0.8rem;
  }

  .welcome-banner-container-btns .welcome-btn-style {
    font-size: 13px;
    height: 34px;
    padding: 0 15px;
  }
}

@media (max-width: 340px) {
  .welcome-banner-container-title h1 {
    font-size: 1.2rem;
    padding: 0rem;
  }

  .welcome-banner-container-title p {
    font-size: 0.7rem;
  }

  .welcome-banner-container-btns .welcome-btn-style {
    font-size: 10px;
    height: 32px;
    padding: 0 10px;
  }
  .welcome-banner-container-title .welcome-banner-icon {
    width: 1rem !important;
    height: 1rem !important;
  }
}

/* media query ends */
