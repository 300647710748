.update-password-div {
    padding: 10px 80px;
}

.update-password-div-card {
    width: 50%;
}

.update-password-div-card-form-input {
    margin-bottom: 10px;
}