.qr-code-reader-container {
  margin: 2rem 0;
  padding: 0 12rem;
}

@media (max-width: 1400px) {
  .qr-code-reader-container {
    padding: 0 5rem;
  }
}

@media (max-width: 1000px) {
  .qr-code-reader-container {
    padding: 0 2rem;
  }
}
@media (max-width: 500px) {
  .qr-code-reader-container {
    padding: 0 1rem;
  }
}
