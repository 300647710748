.backdropfilter-container {
  width: 100%;
  height: 20vh;
  background-image: url("../../Assets/BackDropFilter.jpg");
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
  margin-bottom: 4rem;
  margin-left: -5rem;
  z-index: 0 !important;
}

.backdropfilter-container-dhiyanesh {
  padding: 0 12rem;
  margin: 7rem 0 4rem 0;
}

.backdropfilter-container-withimage {
  display: flex;
  align-items: center;
}

.backdropfilter-container-image {
  width: 700px;
  height: 100%;
  z-index: 2;
}

.backdropfilter-container-image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  z-index: 2;
}

.backdropfilter-container-content {
  margin-left: 4rem;
}

.backdropfilter-container-content h1 {
  font-size: 3rem;
  color: white;
  margin-left: 3rem;
}
.backdropfilter-container-content h3 {
  font-size: 2rem;
  color: white;
  margin-left: 3rem;
}

.backdropfilter-container-content p {
  color: white;
  margin-left: 3rem;
  font-size: 1rem;
}

.backdropfilter-container-button button {
  margin-right: 7rem;
}

/* Media Query  */

@media (max-width: 1400px) {
  .backdropfilter-container-dhiyanesh {
    padding: 0 5rem;
    margin: 5rem 0 4rem 0;
  }
}

@media (max-width: 1200px) {
  .backdropfilter-container-content {
    margin-left: 2rem;
  }
  .backdropfilter-container-button button {
    margin-right: 2rem;
  }
}
@media (max-width: 1060px) {
  .backdropfilter-container-content h3 {
    font-size: 20px;
  }

  .backdropfilter-container-content p {
    font-size: 10px;
  }
}

@media (max-width: 860px) {
  .backdropfilter-container-image {
    width: 400px;
    height: 100%;
    z-index: 2;
    margin-bottom: 1.5rem;
  }
}
@media (max-width: 760px) {
  .backdropfilter-container-image {
    width: 200px;
    height: 100%;
    z-index: 2;
    margin-bottom: 1.5rem;
  }
  .backdropfilter-container {
    width: 100%;
    height: 100%;
    padding: 1.5rem 1rem;
  }
}

@media (max-width: 650px) {
  .backdropfilter-container {
    padding: 1rem 1rem;
  }
}
@media (max-width: 680px) {
  .backdropfilter-container-content h3 {
    font-size: 10px;
  }

  .backdropfilter-container-content p {
    font-size: 5px;
  }
  .backdropfilter-container-dhiyanesh {
    padding: 0 2rem;
    margin: 3rem 0 4rem 0;
  }
  .backdropfilter-container-button button {
    margin-right: 0rem;
  }
}
@media (max-width: 580px) {
  .backdropfilter-container-content h3 {
    font-size: 8px;
    margin-left: 1.5rem;
  }

  .backdropfilter-container-content p {
    font-size: 4px;
    margin-left: 1.5rem;
  }
  .backdropfilter-container-dhiyanesh {
    padding: 0 2rem;
    margin: 3rem 0 4rem 0;
  }
}

/* Media Query  End */
