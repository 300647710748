.vcard-div {
  width: 100%;
}

.vcard-div-image {
  margin: auto;
  width: 50%;
  display: flex;
  justify-content: center;
}

.vcard-div-image img {
  width: 100%;
  height: 100%;
}
.vcard-div-contact-important {
  text-align: center;
}

.vcard-div-image-company-logo {
  width: 200px;
  height: 200px;
  background-color: black;
  display: flex;
  align-items: center;
}

.vcard-div-image-company-logo img {
  width: 100%;
  height: 100%;
}

.vcard-div-content {
  margin: auto;
  width: 50%;
  padding: 2rem 1rem;
}

.vcard-div-content-job-title h3 {
  font-weight: 500;
}

.vcard-div-content-company h3 {
  color: orange;
}

.vcard-div-content-company-description {
  font-size: 12px;
}

.vcard-div-content-link {
  padding: 2rem 0rem;
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
}

.vcard-div-content-link-individual {
  width: 100%;
  height: 40px;
  background-color: rgb(235, 235, 235);
  border-radius: 8px;
  justify-content: center;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
}

.vcard-div-content-link-individual-content {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  width: 250px;
}

.vcard-div-content-save-button {
  display: flex;
  justify-content: center;
}

.save-contact-btn {
  padding: 0.5rem;
  cursor: pointer;
  border-radius: 3px;
  background-color: var(--Logo-Color2);
  color: white;
  border: 0px;
}

/* .vCard-ui-page {
  width: 100%;
  padding: 0 20rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 4rem;
}

.vCard-ui-card-container-profile {
  margin: 0;
  height: 25vh;
  display: flex;
  margin: 1rem 1.6rem 0.5rem 1.6rem;
  gap: 0.2rem;
}

.vCard-ui-card-container-profile-image {
  width: 50%;
  height: 25vh;
}

.vCard-ui-card-container-profile-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 4px;
}

.vCard-ui-card-container-profile-details {
  display: flex;
  border-radius: 4px;
  width: 50%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--Logo-Color3);
  color: white;
}

.vCard-ui-card-container-icons {
  background-color: #228be6;
  border-radius: 4px;
  padding: 0.5rem 0rem 0rem 0rem;
  margin: 0 1.5rem 0 1.6rem;
}

.vcard-ui-social-links {
  transform: scale(0.95);
  margin: 1rem 0;
  padding: 0 5rem;
}
.vcard-ui-address-container {
  margin: 0;
  display: flex;
  margin: 2.5rem 1.6rem 0.5rem 1.6rem;
  gap: 0.2rem;
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 5px;
  padding: 0 0.5rem 1rem 0.5rem;
  background-color: var(--Logo-Color3);
  color: white;
}

.vcard-ui-address-container-title {
  width: 200px;
  background-color: #228be6;
  position: absolute;
  top: -1.1rem;
  border-radius: 10px;
  transform: translateX(50%);
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 0.1rem 0;
  border: 1px solid white;
}

@media (max-width: 1060px) {
  .vCard-ui-page {
    padding: 0 10rem;
  }
}
@media (max-width: 800px) {
  .vCard-ui-page {
    padding: 0 3rem;
  }
}
@media (max-width: 600px) {
  .vCard-ui-page {
    padding: 0 1rem;
  }
}
@media (max-width: 500px) {
  .vCard-ui-card-container-profile {
    margin: 1rem 0.6rem 0.5rem 0.6rem;
  }
  .vCard-ui-card-container-icons {
    margin: 0 0.5rem 0 0.6rem;
  }
  .vcard-ui-address-container {
    margin: 2.5rem 0.6rem 0.5rem 0.6rem;
  }
  .vCard-ui-card-container-profile {
    height: 18vh;
  }
  .vCard-ui-card-container-profile-image {
    height: 18vh;
  }
  .vcard-ui-social-links {
    transform: scale(0.95);
    margin: 1rem 0;
    padding: 0 2.5rem;
  }
}
@media (max-width: 450px) {
  .vCard-ui-card-container-profile {
    margin: 1rem 0.0rem 0.5rem 0.0rem;
  }
  .vCard-ui-card-container-icons {
    margin: 0 0.5rem 0 0.0rem;
  }
  .vcard-ui-address-container {
    margin: 2.5rem 0.0rem 0.5rem 0.0rem;
  }
  .vCard-ui-card-container-profile {
    height: 18vh;
  }
  .vCard-ui-card-container-profile-image {
    height: 18vh;
  }
  .vcard-ui-social-links {
    transform: scale(0.95);
    margin: 1rem 0;
    padding: 0 1.5rem;
  }
  .vcard-ui-address-container-title {
    transform: translateX(28%);
  }
} */

/* Dhiyanesh CSS */

.vcard-ui-conatiner {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.vcard-ui-conatiner-card {
  width: 350px;
  height: 90vh;
  position: relative;
  overflow-y: scroll;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.vcard-ui-conatiner-card-top-profile-image {
  border: 2px solid white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.vcard-ui-conatiner-card-top {
  width: 100%;
  height: 33.5vh;
  background-color: var(--Logo-Color3);
  padding-bottom: 2.5rem;
  background-repeat: no-repeat;
  background-size: cover;
}
.vcard-ui-conatiner-card-bottom {
  width: 100%;
  height: 40vh;
}

.vcard-ui-conatiner-card-bottom-icons {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 30.8vh;
}

.theme-icon-box-shadow {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 50%;
  transition: all 500ms ease-in-out;
  cursor: pointer;
}
.theme-icon-box-shadow1 {
  /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
  border-radius: 50%;
  transition: all 500ms ease-in-out;
  cursor: pointer;
}

.theme-icon-box-shadow1:hover {
  transform: scale(1.3);
  transition: all 500ms ease-in-out;
}

.vcard-ui-conatiner-card-bottom-text {
  margin-top: 1.5rem;
}

.vcard-ui-conatiner-card-bottom-text-input {
  padding-left: 1rem;
  padding-bottom: 0.5rem;
  border-bottom: 0.1px solid #7f8bb18e;
}

.vcard-ui-conatiner-card-bottom-social-media {
  margin-top: 1.5rem;
}

.vcard-ui-save-btn {
  position: relative;
  animation: zoom-in 3000ms infinite linear;
}

@keyframes zoom-in {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(1.1);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
  }
}

@media (max-width: 380px) {
  .vcard-ui-conatiner-card {
    width: 100%;
    height: 100%;
  }
  .vcard-ui-conatiner-card-top {
    width: 100%;
    height: 38vh;
    background-color: var(--Logo-Color3);
    padding-bottom: 2.5rem;
  }
  .vcard-ui-conatiner-card-bottom {
    width: 100%;
    height: 40vh;
  }
  .vcard-ui-conatiner-card-bottom-icons {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 30vh;
  }
}

@media (min-width:380px) {
  .media-apply{
    height: 20vh !important;
  }
}

@media (min-width:1440px) {
  .media-apply{
    height: 30vh !important;
  }
}