.qr-code-generator-container {
  width: 100%;
  height: 100%;
  padding: 3rem 6rem;
  gap: 1rem;
  display: grid;
  grid-template-columns: 700px 600px;
  justify-content: space-around;
}

.qr-code-generator-container-inputs {
  display: grid;
  padding: 2rem;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  background-color: white;
  color: black;
  border-radius: 10px;
}

.style-container {
  padding: 1rem 0;
}

.qr-code-generator-container-qr-code {
  display: grid;
  justify-content: center;
  align-items: center;
  max-height: 100vh;
  border-radius: 10px;
  padding: 1rem;
  background: transparent;
  pointer-events: none;
}

.qr-code-generator-container-inputs-control:hover {
  background-color: var(--Logo-Color3);
  color: white;
  transition: all 300ms ease-in-out;
}

@media print {
  html,
  body {
    display: none;
  }
}

@media (max-width: 1300px) {
  .qr-code-generator-container {
    padding: 3rem 2rem;
    grid-template-columns: 500px 300px;
  }
}
@media (max-width: 700px) {
  .qr-code-generator-container {
    padding: 3rem 2rem;
    grid-template-columns: auto;
  }
}

@media (max-width: 500px) {
  .qr-code-generator-container {
    padding: 3rem 2rem;
    grid-template-columns: 100%;
  }
}
