.frequently-asked-container {
  padding: 0 16rem;
}

.frequently-asked-container-accordion {
  margin: 3rem 0;
}

@media (max-width: 1000px) {
  .frequently-asked-container {
    padding: 0 5rem;
  }
}
@media (max-width: 786px) {
  .frequently-asked-container {
    padding: 0 2rem;
  }
}
@media (max-width: 500px) {
  .frequently-asked-container-accordion {
    margin: 1rem 0;
  }
}
