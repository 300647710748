.uploadimage-div {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.uploadimage-div-form {
    width: 30%;
}

.uploadimage-div-form-div {
    width: 100%;
    height: 100%;
    padding: 1rem;
}