.nav-bar-container {
  width: 100%;
  padding: 1rem 11rem;
  border-bottom: 0.5px solid rgba(138, 135, 135, 0.205);
}

.nav-bar-logo {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}

.nav-bar-logo img {
  width: 100%;
  height: 100%;
}

.nav-bar-links ul {
  display: flex;
  gap: 2rem;
}

.nav-bar-links ul li {
  list-style-type: none;
  cursor: pointer;
  transition: all 500ms ease-in-out;
}

.nav-bar-links ul li:hover {
  transition: all 500ms ease-in-out;
  color: rgba(5, 5, 5, 0.5);
}

.nav-bar-links-2 ul {
  display: flex;
  gap: 2rem;
  justify-content: flex-start;
}

.nav-bar-links-2 ul li {
  list-style-type: none;
  cursor: pointer;
  transition: all 500ms ease-in-out;
}

.nav-bar-links-2 ul li:hover {
  transition: all 500ms ease-in-out;
  color: rgba(5, 5, 5, 0.5);
}

.nav-bar-link-last-2-child:hover .nav-bar-link-icon {
  transform: scale(1.2);
}

.nav-bar-link-last-2-child {
  color: #1357be !important;
}

/* media query starts */

.mobile-nav-bar-container {
  width: 100%;
  padding: 1rem;
  border-bottom: 0.5px solid rgba(138, 135, 135, 0.205);
}

@media (max-width: 1290px) {
  .nav-bar-container {
    padding: 1rem 6rem;
  }

  .nav-bar-links {
    justify-content: space-around !important;
  }

  .nav-bar-links ul {
    gap: 0.5rem;
  }
}

@media (max-width: 1025px) {
  .nav-bar-container {
    padding: 1rem 3rem;
  }

  .nav-bar-links {
    justify-content: center !important;
  }

  .nav-bar-links ul {
    gap: 1rem !important;
  }
}

@media (max-width: 415px) {
  .mobile-nav-bar-container-content {
    grid-template-columns: 60% 25% !important;
  }
}

.mobile-nav-bar-container-content {
  display: grid;
  justify-content: space-between;
  width: 100%;
  grid-template-columns: 40% 25%;
  align-items: center;
}

.mobile-nav-bar-container-content-right {
  display: flex;
  justify-content: flex-end;
}

.nav-drawer-header {
  position: relative;
}

.nav-drawer-header .mantine-Drawer-close {
  position: absolute;
  top: 4px;
  right: 4px;
}

.nav-bar-logo-drawer {
  width: 80%;
  height: 80%;
  display: flex;
  justify-content: center;
}

.nav-bar-logo-drawer img {
  width: 100%;
  height: 100%;
}

.drawer-navbar-link {
  display: flex;
  flex-direction: column;
}

.drawer-navbar-link ul {
  display: flex;
  flex-direction: column;
  list-style-type: none;
  gap: 1rem;
  padding: 1rem;
}

/* .drawer-navbar-link ul:first-child
{
  padding-top: 1.5rem;
} */

/* media query ends */

.mantine-Menu-dropdown {
  z-index: 10 !important;
}