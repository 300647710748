.pricing-container-title {
  margin: 2rem 0;
}

.pricing-container-grid {
  padding: 0rem 16rem;
  margin: 6rem 0 4rem 0;
}

.pricing-container-grid-pricing-list ul li {
  list-style-type: none;
  padding: 0 0.4rem;
}

.pricing-container-grid-pricing-list ul li p {
  font-size: 16px;
}
.pricing-container-grid-pricing-list ul li p span {
  font-weight: 700;
}

.pricing-container-grid-button {
  margin: 2rem 0 0 0;
}

.pricing-container-grid-button button {
  width: 100%;
  background-color: var(--Logo-Color5);
}

.pricing-container-grid-button button:hover {
  background-color: var(--Logo-Color3);
}

.pricing-container-grid-card {
  position: relative;
  overflow: visible;
  width: 100%;
  z-index: 123;
}

.pricing-container-grid-card-top-title {
  position: absolute;
  top: -2.8rem;
  left: 2rem;
  width: 80%;
  margin: auto;
  padding: 0.3rem 0;
  margin: 0;
  z-index: 0;
  border-radius: 20px 20px 0 0;
  background-color: var(--Logo-Color3);
  color: white;
}

.green-btn{
  background: linear-gradient(45deg, #d8152f,#d1ce17) !important;
  width: 100% !important;
  border: transparent !important;
}

/* Media Query */

@media (max-width: 1500px) {
  .pricing-container-grid {
    padding: 0rem 5rem;
    margin: 6rem 0 4rem 0;
  }
}
@media (max-width: 1120px) {
  .pricing-container-grid {
    padding: 0rem 2rem;
    margin: 6rem 0 4rem 0;
  }
}
@media (max-width: 1000px) {
  .pricing-last-grid {
    margin-top: 4rem;
    align-self: center;
    justify-self: center;
  }
}
@media (max-width: 600px) {
  .pricing-container-grid-card-top-title {
    left: 3rem;
  }
  .pricing-container-grid-card {
    position: relative;
    overflow: visible;
    width: 100%;
    z-index: 1;
  }
}
@media (max-width: 500px) {
  .pricing-container-grid-card-top-title {
    left: 2rem;
  }
  .pricing-container-grid {
    padding: 0rem 1rem;
  }
}

/* Media Query End */
