.qrtypes-container {
  width: 100%;
  padding: 0 12rem;
}

.qrtypes-container-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 3rem;
}

.qrtypes-container-title h1 {
  font-size: 3.5rem;
  margin: 1rem 0 0 0;
}

.qrtypes-container-grid-btn {
  background-color: rgba(216, 216, 216, 0.471);
  color: black;
  transition: all 300ms ease-in-out;
}

.qrtypes-container-grid-btn:hover {
  background-color: var(--Logo-Color3);
  color: white;
  transition: all 300ms ease-in-out;
}

/* Media Query */

@media (max-width: 1400px) {
  .qrtypes-container {
    width: 100%;
    padding: 0 5rem;
  }
}
@media (max-width: 1060px) {
  .qrtypes-container {
    padding: 0 4rem;
  }
}
@media (max-width: 786px) {
  .qrtypes-container {
    padding: 0 2rem;
  }
}
@media (max-width: 500px) {
  .qrtypes-container {
    padding: 0 1rem;
  }
}

/* Media Query End */
