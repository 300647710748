.register-container .login-div {
  padding: 200px 80px;
  height: 100vh;
  background-color: var(--Logo-Color2-Light);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.register-container .login-div-card {
  width: 25%;
  background-color: white;
  border-radius: 8px;
  box-shadow: rgba(169, 166, 166, 0.556) 0px 7px 29px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.register-container .login-div-card-logo-div {
  padding-top: 1rem;
  width: 70px;
  height: auto;
}

.register-container .login-div-card-logo-div img {
  width: 100%;
  height: 100%;
}

.register-container .login-div-card-form {
  width: 100%;
  padding: 1rem 1.5rem 0rem;
}

.register-container .login-div-card-form-individual {
  margin-bottom: 1rem;
}

.register-container .login-div-card-form-individual-btn {
  width: 100%;
}

.register-container .login-div-card-form-individual-btn button {
  border: 0px;
  background-color: var(--Logo-Color2);
  color: white;
  cursor: pointer;
  width: 100%;
  height: 35px;
  border-radius: 3px;
}

.register-container .login-div-card-form-individual-btn button:hover {
  background-color: var(--Logo-Color2-Light);
}

@media (max-width: 1500px) {
  .register-container .login-div {
    padding: 200px 10px;
  }
}
@media (max-width: 1300px) {
  .register-container .login-div-card {
    width: 360px;
  }
}
