.pro-sub-menu.collapsed .pro-icon-wrapper {
  content: "\f105";
}

/* .css-jn69v9   .css-1097eso */
.tooltip-content {
  position: fixed !important;
  bottom: 10px;
  right: 20px;
  z-index: 9999;
}

.admin-sidenav {
  display: flex;
  min-height: 100vh;
}

.ps-sidebar-container::-webkit-scrollbar {
  display: none;
}

.admin-sidebar-logo {
  width: 100%;
  padding: 0.5rem 0.8rem;
  position: sticky;
  top: 0;
  background-color: #fbfbfb;
  z-index: 1;
}

.admin-sidebar-logo-img {
  width: 60px;
  /* height: 78.13px; */
  margin: auto;
}

.admin-sidebar-logo-img-favicon {
  width: 50px !important;
  height: 50px !important;
}

.admin-sidebar-logo img {
  width: 100%;
  height: 100%;
}

.Logo_favicon {
  width: 50px !important;
  height: 50px !important;
}

.admin-sidenav-main-admin-content {
  background-color: #f3f6f8;
  width: 100%;
}

.admin-sidenav-topbar-color {
  box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px;
  width: 100%;
  min-height: 2rem;
  padding: 0.3rem 2.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffff;
  z-index: 12;
  position: sticky;
  top: 0;
}

.admin-sidenav-toggle-style-button {
  border: 0;
  background-color: #0000;
  font-size: 20px;
}

.admin-sidenav-toggle-style-button:hover {
  cursor: pointer;
}

.admin-user-profile-dropdown {
  display: flex;
  gap: 1.5rem;
  align-items: center;
}
.admin-notification-icon {
  position: relative;
}
.admin-notification-icon .mantine-1nbod7e {
  top: 30px !important;
  padding: 1rem;
}

.admin-user-profile-dropdown-button-style:hover {
  cursor: pointer;
}

.admin-user-profile-dropdown-button-style {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.admin-user-profile-dropdown-button-img {
  width: 40px;
  height: 40px;
  clip-path: circle();
  object-fit: contain;
}

.admin-user-profile-dropdown-button-img img {
  width: 100%;
  height: 100%;
}

.admin-user-profile-dropdown .mantine-eby8v3 {
  align-items: baseline;
  color: #777777;
}

.admin-user-profile-dropdown .mantine-eby8v3:hover {
  color: #333333;
}

.admin-all-pages-content {
  padding: 1rem 0.5rem;
  width: 100%;
  min-height: 90vh;
}

.admin-sidenav a {
  color: rgba(0, 0, 0, 0.8);
  text-decoration: none;
}

.admin-sidenav .admin-sidenav-menu-style {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 14px;
}
